import React from "react"
import { FaHeart as Heart } from "react-icons/fa"
import { FiMapPin as GMapPin } from "react-icons/fi"
import TechIcon from "./tech-icon"
import SocialIcon from "./social-icon"

export default function Info() {
  return (
    <div className="container-fluid main">
      <div className="display-3" style={{ color: "#02070d" }}>
        Oleg Höfling
      </div>
      <div className="my-4 h3 text-center info">
        <div className="text-muted">
          Software Developer
          <br /> <GMapPin /> Berlin, Germany
        </div>
        <div className="my-5"></div>
        <span className="icon">
          <Heart />{" "}
        </span>{" "}
        to work with:
        <div className="my-2"></div>
        <div className="h2">
          <TechIcon iconType="linux" />
          <TechIcon iconType="bash" />
          <TechIcon iconType="git" />
          <TechIcon iconType="python" />
          <TechIcon iconType="rust" />
          <TechIcon iconType="java" />
        </div>
      </div>
      <div className="h1 mt-5">
        <SocialIcon iconType="github" url="https://github.com/hoefling" />
        <SocialIcon
          iconType="stackoverflow"
          url="https://stackoverflow.com/users/2650249/hoefling"
        />
        <SocialIcon
          iconType="linkedin"
          url="https://www.linkedin.com/in/hoefling"
        />
        <SocialIcon iconType="telegram" url="https://t.me/hoefling" />
        <SocialIcon iconType="mail" url="mailto://oleg.hoefling@gmail.com" />
      </div>
    </div>
  )
}
