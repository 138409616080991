import React from "react"
import Tooltip from "react-bootstrap/Tooltip"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"

import {
  FaGithub,
  FaLinkedinIn,
  FaStackOverflow,
  FaTelegram,
  FaEnvelope,
} from "react-icons/fa"

const iconComponents = {
  github: { component: FaGithub, tooltip: "GitHub" },
  linkedin: { component: FaLinkedinIn, tooltip: "LinkedIn" },
  stackoverflow: { component: FaStackOverflow, tooltip: "Stack Overflow" },
  telegram: { component: FaTelegram, tooltip: "Telegram" },
  mail: { component: FaEnvelope, tooltip: "mail me!" },
}

export default function SocialIcon(props) {
  const Icon = iconComponents[props.iconType].component
  const tooltip = iconComponents[props.iconType].tooltip
  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 250, hide: 400 }}
      overlay={
        <Tooltip id={`tooltip-${props.iconType}`}>
          <strong>{tooltip}</strong>
        </Tooltip>
      }
    >
      <a className="mx-2 mx-md-3 icon icon-social" href={props.url}>
        <Icon />
      </a>
    </OverlayTrigger>
  )
}
