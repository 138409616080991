import React from "react"
import "../styles/style.css"
import Info from "../components/info"
import { Helmet } from "react-helmet"

function index() {
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Info />
      <Helmet>
        <meta charSet="utf-8" />
        <title>hoefling.io</title>
        <link rel="canonical" href="https://hoefling.io" />
      </Helmet>
    </div>
  )
}

export default index
