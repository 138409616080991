import React from "react"
import Tooltip from "react-bootstrap/Tooltip"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"

import {
  DiGit as Git,
  DiGo as Golang,
  DiJava as Java,
  DiLinux as Linux,
  DiPython as Python,
  DiRust as Rust,
  DiTerminal as Bash,
} from "react-icons/di"

const iconComponents = {
  bash: { iconComponent: Bash, tooltip: "Bash" },
  git: { iconComponent: Git, tooltip: "git" },
  golang: { iconComponent: Golang, tooltip: "Go" },
  java: { iconComponent: Java, tooltip: "Java" },
  linux: { iconComponent: Linux, tooltip: "Linux" },
  python: { iconComponent: Python, tooltip: "Python" },
  rust: { iconComponent: Rust, tooltip: "Rust" },
}

export default function TechIcon(props) {
  const Icon = iconComponents[props.iconType].iconComponent
  const tooltip = iconComponents[props.iconType].tooltip
  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 250, hide: 400 }}
      overlay={
        <Tooltip id={`tooltip-${props.iconType}-${tooltip}`}>
          <strong>{tooltip}</strong>
        </Tooltip>
      }
    >
      <span className="mx-2 icon icon-tech">
        <Icon />
      </span>
    </OverlayTrigger>
  )
}
